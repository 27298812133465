<template>
  <BaseModal
    ref="modal"
    title="Contacten samenvoegen"
    max-width="tw-max-w-5xl"
  >
    <template v-if="infoText">
      <p class="tw-italic">
        {{ infoText }}
      </p>
      <button
        type="button"
        class="btn btn-default btn-xs tw-mb-4"
        @click="skipMerge"
      >
        Niet samenvoegen
      </button>
    </template>
    <ContactMergeModalStep1
      v-if="activeStep === 1"
      :preselected-primary-contact="primaryContact"
      :preselected-merged-contact="mergedContact"
      @finished="finished"
    />
    <ContactMergeModalStep2
      v-if="activeStep === 2"
      :primary-contact="primaryContact"
      :merged-contact="mergedContact"
      :merge-payload="mergePayload"
      @goBack="goBack"
      @finished="finished"
    />
  </BaseModal>
</template>

<script>
import { getContactById } from '@/services/contacts'
import ContactMergeModalStep1 from '@/components/contacts/ContactMergeModalStep1'
import ContactMergeModalStep2 from '@/components/contacts/ContactMergeModalStep2'
export default {
  name: 'ContactMergeModalV2',
  components: {
    ContactMergeModalStep1,
    ContactMergeModalStep2
  },
  props: {
    infoText: {
      type: String,
      default: ''
    },
    shouldRedirectToMergedContact: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      activeStep: 1,
      primaryContact: null,
      mergedContact: null,
      mergePayload: null
    }
  },
  methods: {
    hide () {
      this.$refs.modal.hide()
    },
    goBack () {
      this.activeStep -= 1
    },
    finished (data) {
      if (this.activeStep === 1) {
        this.primaryContact = data.primaryContact
        this.mergedContact = data.mergedContact
        this.mergePayload = data.mergePayload
        this.activeStep = 2
      } else if (this.shouldRedirectToMergedContact) {
        this.activeStep = 1
        this.$router.push({ name: 'ContactDetails', params: { id: this.primaryContact.id } })
      } else {
        this.activeStep = 1
        this.$emit('contactsMerged', this.mergedContact.id)
        this.hide()
      }
    },
    skipMerge () {
      this.hide()
      this.$emit('contactsMergeCancelled')
    },
    async show (primaryContactId = null, mergedContactId = null) {
      let primaryContactResponse
      let mergedContactResponse
      if (primaryContactId) {
        primaryContactResponse = await getContactById(primaryContactId)
        this.primaryContact = primaryContactResponse.data
      }
      if (mergedContactId) {
        mergedContactResponse = await getContactById(mergedContactId)
        this.mergedContact = mergedContactResponse.data
      }
      this.$refs.modal.show()
      return [primaryContactResponse, mergedContactResponse]
    }
  }
}
</script>
