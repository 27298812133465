<template>
  <div>
    <FormulateForm
      #default="{ isLoading }"
      name="ContactMergeModalStep2"
      invalid-message="Gelieve de verplichte velden correct in te vullen."
      @submit="submit"
    >
      <h2 class="tw-mt-0">Geselecteerde contacten</h2>
      <div class="md:tw-grid tw-grid-cols-2 tw-gap-x-16 tw-gap-y-8">
        <div>
          <p class="formulate-label">Behouden contact</p>
          <p class="md:tw-mb-0 tw-mb-4">{{ primaryContact.display_name }}</p>
        </div>
        <div>
          <p class="formulate-label">Samen te voegen contact</p>
          <p class="tw-mb-0">{{ mergedContact.display_name }}</p>
        </div>
      </div>
      <hr class="tw-my-4" />
      <h2 class="tw-my-0">Facturatiecontacten</h2>
      <div class="md:tw-grid tw-grid-cols-2 tw-gap-x-16 tw-gap-y-8">
        <div
          v-for="(invoiceContacts, index) in [invoiceContactsPrimaryContact, invoiceContactsMergedContact]"
          :key="index"
          class="tw-mt-4"
        >
          <p class="formulate-label tw-mb-2">Facturatiecontacten voor {{ index === 0 ? 'behouden contact' : 'samen te voegen contact' }}</p>
          <template v-if="invoiceContacts.length">
            <div class="tw-flex tw-flex-wrap tw-gap-y-2">
              <div v-for="invoiceContact in invoiceContacts" :key="invoiceContact.id" class="tw-w-full tw-shadow-md tw-border tw-p-2 tw-rounded-md">
                <span class="tw-block tw-font-bold">{{ invoiceContact.title }}</span>
                <span class="tw-block">{{ invoiceContact.external_number ? `${invoiceContact.external_number} (BoCount)` : '-' }}</span>
                <span class="tw-block">{{ getInvoiceContactAddress(invoiceContact) }}</span>
                <div class="tw-mt-1 tw-flex tw-flex-wrap tw-gap-2">
                  <router-link
                    v-for="({ contact, primary }) in invoiceContact.contacts"
                    :key="contact.id"
                    :to="{ name: 'ContactDetails', params: { id: contact.id } }"
                    target="_blank"
                    :class="[
                      'label hover:!tw-underline',
                      primary
                        ? 'tw-bg-success !tw-text-white'
                        : 'tw-bg-white tw-border tw-border-success !tw-text-success'
                    ]"
                  >
                    {{ contact.display_name }}
                  </router-link>
                </div>
              </div>
            </div>
          </template>
          <p v-else>Geen gelinkte facturatiecontacten.</p>
        </div>
      </div>
      <hr class="tw-my-4" />
      <h2 class="tw-my-0">Openstaande kosten</h2>
      <div class="md:tw-grid tw-grid-cols-2 tw-gap-x-16 tw-gap-y-8">
        <div
          v-for="(costs, index) in [costsPrimaryContact, costsMergedContact]"
          :key="index"
          class="tw-mt-4"
        >
          <p class="formulate-label tw-mb-2">Openstaande kosten voor {{ index === 0 ? 'behouden contact' : 'samen te voegen contact' }}</p>
          <template v-if="costs.length">
            <table class="tw-table-auto tw-w-full">
              <tr class="tw-font-bold tw-border">
                <th class="tw-p-1 tw-border tw-pl-2">Dossier</th>
                <th class="tw-p-1 tw-border tw-pl-2">Type kost</th>
                <th class="tw-p-1 tw-border tw-pl-2">Bedrag</th>
              </tr>
              <tr
                v-for="cost in costs"
                :key="cost.id"
                class="tw-border"
              >
                <td class="tw-p-1 tw-border tw-pl-2">{{ cost.entity.reference }}</td>
                <td class="tw-p-1 tw-border tw-pl-2">{{ cost.product }}</td>
                <td class="tw-p-1 tw-border tw-pl-2">{{ currency(cost.amount) }}</td>
              </tr>
            </table>
          </template>
          <p v-else>
            Geen openstaande kosten.
          </p>
        </div>
      </div>
      <hr class="tw-my-4" />
      <h2 class="tw-my-0">
        Facturen
        <Tooltip class="tw-inline-block tw-text-sm">
          <i class="fas fa-info-circle" />
          <template #popper>
            Facturen zijn reeds geboekt in BoCount en worden niet samengevoegd.
          </template>
        </Tooltip>
      </h2>
      <div class="md:tw-grid tw-grid-cols-2 tw-gap-x-16 tw-gap-y-8">
        <div
          v-for="(invoices, index) in [invoicesPrimaryContact, invoicesMergedContact]"
          :key="index"
          class="tw-mt-4"
        >
          <p class="formulate-label tw-mb-2">Facturen voor {{ index === 0 ? 'behouden contact' : 'samen te voegen contact' }}</p>
          <template v-if="invoices.length">
            <table class="tw-table-auto tw-w-full">
              <tr class="tw-font-bold tw-border">
                <th class="tw-p-1 tw-border tw-pl-2">Dossier</th>
                <th class="tw-p-1 tw-border tw-pl-2">Factuurnummer</th>
                <th class="tw-p-1 tw-border tw-pl-2">Bedrag (incl. btw)</th>
              </tr>
              <tr
                v-for="invoice in invoices"
                :key="invoice.id"
                class="tw-border"
              >
                <td class="tw-p-1 tw-border tw-pl-2">{{ invoice.entities[0].reference }}</td>
                <td class="tw-p-1 tw-border tw-pl-2">{{ invoice.invoice_number }}</td>
                <td class="tw-p-1 tw-border tw-pl-2">{{ currency(invoice.invoice_amount_incl_vat) }}</td>
              </tr>
            </table>
          </template>
          <p v-else>
            Geen facturen.
          </p>
        </div>
      </div>
      <div v-if="primaryContact && mergedContact" class="tw-grid tw-grid-cols-2 md:tw-flex tw-justify-end tw-gap-x-2">
        <FormulateErrors />
        <FormulateInput
          type="button"
          :input-class="['tw-w-full tw-bg-gray-500']"
          @click="goBack"
        >
          <i class="fas tw-mr-2 fa-arrow-left" />
          Vorige <span class="tw-hidden md:tw-inline">stap</span>
        </FormulateInput>
        <FormulateInput
          type="submit"
          title="Volgende stap"
          :input-class="['tw-w-full']"
          :disabled="isLoading"
        >
          <i
            :class="[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-link'
            ]"
          />
          Samenvoegen
        </FormulateInput>
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import { mergeContacts } from '@/services/contacts'
import { getCosts } from '@/services/costs'
import { getInvoiceContacts, getInvoices } from '@/services/invoices'
import { currency, getInvoiceContactAddress, getInvoiceContactPrimary } from '@/utils/helpers'
import { Tooltip } from 'floating-vue'
export default {
  name: 'ContactMergeModalStep2',
  components: {
    Tooltip
  },
  props: {
    primaryContact: {
      type: Object
    },
    mergedContact: {
      type: Object
    },
    mergePayload: {
      type: Object
    }
  },
  data () {
    return {
      invoiceContactsPrimaryContact: [],
      invoiceContactsMergedContact: [],
      costsPrimaryContact: [],
      costsMergedContact: [],
      invoicesPrimaryContact: [],
      invoicesMergedContact: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    currency,
    getInvoiceContactAddress,
    getInvoiceContactPrimary,
    async init () {
      const promises = [
        this.fetchInvoiceContactsForContact('primary'),
        this.fetchInvoiceContactsForContact('merged'),
        this.fetchInvoicesForContact('primary'),
        this.fetchInvoicesForContact('merged'),
        this.fetchCostsForContact('primary'),
        this.fetchCostsForContact('merged')
      ]
      return await Promise.all(promises)
    },
    goBack () {
      this.$emit('goBack')
    },
    async fetchInvoiceContactsForContact (contactType) {
      const contact = contactType === 'primary' ? this.primaryContact : this.mergedContact
      const params = {
        contact: contact.id,
        is_archived: false,
        page_size: 1000
      }
      const response = await getInvoiceContacts({ params })
      contactType === 'primary'
        ? this.invoiceContactsPrimaryContact = response.data?.results
        : this.invoiceContactsMergedContact = response.data?.results
      return response
    },
    async fetchCostsForContact (contactType) {
      const contact = contactType === 'primary' ? this.primaryContact : this.mergedContact
      const params = {
        contact: contact.id,
        is_invoiceable: true,
        is_settled: false,
        overview: true,
        page_size: 1000
      }
      const response = await getCosts({ params })
      contactType === 'primary'
        ? this.costsPrimaryContact = response.data?.results
        : this.costsMergedContact = response.data?.results
      return response
    },
    async fetchInvoicesForContact (contactType) {
      const contact = contactType === 'primary' ? this.primaryContact : this.mergedContact
      const params = {
        contact: contact.id,
        overview: true,
        page_size: 1000
      }
      const response = await getInvoices({ params })
      contactType === 'primary'
        ? this.invoicesPrimaryContact = response.data?.results
        : this.invoicesMergedContact = response.data?.results
      return response
    },
    async submit () {
      const response = await mergeContacts(this.mergePayload)
      this.$emit('finished')
      return response
    }
  }
}
</script>
