<template>
  <div>
      <div
        v-if="activity.activity_messages.length"
        class="tw-bg-white tw-border tw-rounded tw-shadow-md tw-px-2 tw-mt-2 tw-mb-4"
      >
        <details>
          <summary class="tw-font-bold tw-my-2">Communicatie</summary>
          <table class="tw-table-auto tw-w-full tw-mb-2 tw-text-[10px]">
            <tr
              v-for="message in activity.activity_messages"
              :key="message.id"
              class="tw-border"
            >
              <td class="tw-p-1 tw-border tw-pl-2">
                <span :class="[
                  'fa fa-circle tw-mr-1',
                  messageIsSent(message)
                    ? 'tw-text-green-500'
                    : messageIsPending(message) ? 'tw-text-warning' : 'tw-text-error'
                  ]"
                />
                {{ getMediumType(message) }}
              </td>
              <td
                v-if="messageIsPendingOrSent(message)"
                class="tw-p-1 tw-border tw-pl-2">{{ getStatus(message) }} op {{ getTimestamp(message) }}
              </td>
              <td
                v-else
                class="tw-p-1 tw-border tw-pl-2"
              >
                Verzending mislukt
              </td>
              <td class="tw-p-1 tw-border tw-text-center">
                <button
                  type="button"
                  class="fa fa-eye"
                  @click="showMessageContent(message)"
                />
              </td>
            </tr>
          </table>
        </details>
      </div>
      <BaseModal ref="messageContentModal" title="Details">
      <div v-if="selectedMessage" class="tw-rounded tw-shadow-md tw-p-4">
        <div class="tw-mb-2">Aan: <router-link :to="{ name: 'ContactDetails', params: { id: selectedMessage.message.to.id } }">
            {{ selectedMessage.message.to.display_name }}
          </router-link>
        </div>
        <hr>
        <div
          v-if="selectedMessage.message.subject"
          class="tw-font-bold tw-my-2"
        >
          {{ selectedMessage.message.subject }}
        </div>
        <!-- Database-stored HTML which has been generated by back-end code entirely. No security concerns here. -->
        <div
          v-html="selectedMessage.message.message" class="tw-italic"
        />
      </div>
      <div v-if="files.length">
        <hr>
        <p class="tw-font-bold tw-mt-4 tw-my-2">Bestanden raadpleegbaar via link</p>
        <ul class="tw-list-disc tw-ml-4">
          <li v-for="file in files" :key="file.id">
            <a :href="getFileUrl(file)">{{ getFileName(file) }}</a>
          </li>
        </ul>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { formatDateNlBe } from '@/utils/helpers'
import { getMessageFiles } from '@/services/messages'

export default {
  name: 'ActivityTimelineItemCommunication',
  props: {
    activity: {
      type: Object
    }
  },
  data () {
    return {
      selectedMessage: null,
      files: []
    }
  },
  methods: {
    messageIsPending (message) {
      return message?.message?.status === 0
    },
    messageIsSent (message) {
      return message?.message?.status === 1
    },
    messageIsPendingOrSent (message) {
      return this.messageIsPending(message) || this.messageIsSent(message)
    },
    getMedium (message) {
      if (message?.message?.type === 0) return '(e-mail)'
      else return '(sms)'
    },
    getType (message) {
      switch (message.type) {
        case 1:
          return 'Bevestiging'
        case 2:
          return 'Herinnering'
        case 3:
          return 'Annulatie'
        default:
          return 'Bericht'
      }
    },
    getMediumType (message) {
      return `${this.getType(message)} ${this.getMedium(message)}`
    },
    getStatus (message) {
      if (message.message.sent_at) return 'Verzonden'
      return 'Verzending'
    },
    getTimestamp (message) {
      if (message?.message?.send_at) return formatDateNlBe(message.message.send_at, 'dd-MM-yyyy HH:mm')
      return formatDateNlBe(message?.message?.sent_at, 'dd-MM-yyyy HH:mm')
    },
    getFileUrl (file) {
      return file.property_file ? file.property_file.url : file.project_file.url
    },
    getFileName (file) {
      return file.property_file ? file.property_file.filename : file.project_file.filename
    },
    async showMessageContent (message) {
      this.selectedMessage = message
      const response = await getMessageFiles(message.message.id)
      this.files = response.data?.results
      this.$refs.messageContentModal.show()
      return response
    }
  }
}

</script>
