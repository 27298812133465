<template>
  <div v-if="entities">
    <div class="tw-flex tw-overflow-x-auto tw-gap-8">
      <template v-for="entityCollection in entities">
        <div
          v-for="(item, index) in entityCollection.items" :key="`${index}_${entityCollection.type}`"
          class="tw-shadow-md tw-rounded-md tw-bg-white tw-overflow-hidden tw-min-h-full tw-w-auto tw-flex-shrink-0 tw-max-w-[50%]"
        >
          <img
            :src="item.main_picture_url || noImageAvailable"
            :alt="item.reference"
            loading="lazy"
            class="tw-w-full tw-h-full tw-max-h-[150px] tw-object-cover"
          />
          <div class="tw-p-3.5">
            <router-link :to="{ name: item.routerName, params: { id: item.id } }">
              <h4>{{ item.reference }}</h4>
            </router-link>
            {{ item.address }}<span v-if="entityCollection.type === 'properties'"> - {{ PROPERTY_STATUSES[`status_${item.status_for_user}`] }}</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EntityCarousel',
  props: {
    properties: {
      type: Array
    },
    projects: {
      type: Array
    }
  },
  computed: {
    noImageAvailable () {
      return require('@/assets/img/property_dummy.gif')
    },
    entities () {
      return [
        {
          type: 'properties',
          items: this.properties,
          routerName: 'PropertyDetails'
        },
        {
          type: 'projects',
          items: this.projects,
          routerName: 'ProjectDetails'
        }
      ]
    }
  },
  constants: {
    PROPERTY_STATUSES: {
      status_prospect: 'Prospect',
      status_dossier_preparation: 'Dossieropbouw',
      status_acquired: 'Overgenomen',
      status_acquired_out: 'Overgelaten',
      status_bought: 'Gekocht',
      status_for_acquisition: 'Over te nemen',
      status_for_rent: 'Te huur',
      status_for_sale: 'Te koop',
      status_rented: 'Gehuurd',
      status_rented_out: 'Verhuurd',
      status_sold: 'Verkocht',
      status_rental_management: 'Beheer',
      status_syndic: 'Syndic'
    }
  },
  created () {
    this.init()
  }
}
</script>
