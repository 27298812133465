<template>
  <div>
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      name="ContactMergeModalStep1"
      invalid-message="Gelieve de verplichte velden correct in te vullen."
      @submit="submit"
    >
      <h2 class="tw-mt-0">Contactselectie</h2>
      <div
        v-if="primaryContact && mergedContact"
        class="tw-flex tw-justify-end md:tw-justify-center tw--mt-8 md:tw--mt-9 tw-mb-4"
      >
        <button
          type="button"
          class="btn btn-default btn-xs"
          @click="switchContacts"
        >
            <i class="fas fa-arrow-left" />
            <i class="fa fa-arrow-right tw--ml-1" />
        </button>
      </div>
      <div class="md:tw-grid tw-grid-cols-2 tw-gap-x-16 tw-gap-y-8">
        <FormulateInput
          v-model="primaryContact"
          type="autocomplete"
          auto-complete-type="contact"
          name="primary_contact"
          label="Behouden contact"
          placeholder="Selecteer het behouden contact"
          :outer-class="['tw-mt-0']"
          @input="processContactSelection('primary')"
        >
          <template #label="{ label, id, classes }">
            <div class="tw-flex tw-justify-between">
              <label :for="id" :class="classes.label" v-text="label" class="formulate-label" />
              <div>
                <ContactGDPR v-if="primaryContact" :object="primaryContact" class="tw-inline-block tw-mr-2" />
                <router-link
                  v-if="primaryContact && primaryContact.id"
                  target="_blank"
                  :to="{ name: 'ContactDetails', params: { id: primaryContact.id } }"
                >
                  <i class="fa fa-link" />
                </router-link>
              </div>
            </div>
          </template>
        </FormulateInput>
        <FormulateInput
          v-model="mergedContact"
          type="autocomplete"
          auto-complete-type="contact"
          name="merged_contact"
          label="Samen te voegen contact"
          placeholder="Selecteer het samen te voegen contact"
          :outer-class="['md:tw-mt-0']"
          @input="processContactSelection('merged')"
        >
          <template #label="{ label, id, classes }">
            <div class="tw-flex tw-justify-between">
              <label :for="id" :class="classes.label" v-text="label" class="formulate-label" />
              <div>
                <ContactGDPR v-if="mergedContact" :object="mergedContact" class="tw-inline-block tw-mr-2" />
                <router-link
                  v-if="mergedContact && mergedContact.id"
                  target="_blank"
                  :to="{ name: 'ContactDetails', params: { id: mergedContact.id } }"
                >
                  <i class="fa fa-link" />
                </router-link>
              </div>
            </div>
          </template>
        </FormulateInput>
      </div>
      <template v-if="primaryContact && mergedContact && !contactsAreOfDifferentTypes && !duplicateContactSelection">
        <div class="md:tw-grid tw-grid-cols-2 tw-gap-x-16 tw-gap-y-8">
          <div v-if="primaryContact">
            <details v-if="propertiesPrimaryContact.length || projectsPrimaryContact.length" open>
              <summary class="formulate-label">Gelinkt vastgoed ({{ propertiesPrimaryContact.length  + projectsPrimaryContact.length }})</summary>
              <EntityCarousel
                :properties="propertiesPrimaryContact"
                :projects="projectsPrimaryContact"
              />
            </details>
            <p v-else>Geen vastgoed gelinkt aan {{ primaryContact.display_name }}.</p>
          </div>
          <div v-if="mergedContact" class="tw-mt-4 md:tw-mt-0">
            <details v-if="propertiesMergedContact.length || projectsMergedContact.length" open>
              <summary class="formulate-label">Gelinkt vastgoed ({{ propertiesMergedContact.length  + projectsMergedContact.length }})</summary>
              <EntityCarousel
                :properties="propertiesMergedContact"
                :projects="projectsMergedContact"
              />
            </details>
            <p v-else>Geen vastgoed gelinkt aan {{ mergedContact.display_name }}.</p>
          </div>
        </div>
        <hr class="tw-my-4" />
        <div >
          <h2>Samen te voegen gegevens</h2>
          <p class="tw-text-xs tw-italic">Selecteer hieronder welke gegevens behouden worden.</p>
          <!-- Show customer portal selection only if one contact has customer portal and the other doesn't -->
          <CustomRadioGroup
            v-if="!!primaryContact.user_id !== !!mergedContact.user_id"
            v-model="values.contact_id_customer_portal"
            name='contact_id_customer_portal'
            :value="primaryContact.id"
            :show-label="false"
            :mobile-row-layout="true"
            :options="[
              { label: `${primaryContact.user_id ? 'Klantenportaal' : 'Geen klantenportaal'}`, value: primaryContact.id },
              { label: `${mergedContact.user_id ? 'Klantenportaal' : 'Geen klantenportaal'}`, value: mergedContact.id }
            ]"
            :disabled="currentSelectedEmailOptionIsNoEmail"
          />
          <CustomRadioGroup
            v-if="primaryContact.email !== mergedContact.email"
            v-model="values.contact_id_email"
            name='contact_id_email'
            :value="primaryContact.id"
            :show-label="false"
            :mobile-row-layout="true"
            :options="[
              { label: `${primaryContact.email || 'Geen e-mailadres'}`, value: primaryContact.id },
              { label: `${mergedContact.email || 'Geen e-mailadres'}`, value: mergedContact.id },
            ]"
            @input="emailAddressChanged"
          />
          <CustomRadioGroup
            v-if="primaryContact.mobile !== mergedContact.mobile"
            v-model="values.contact_id_mobile"
            name='contact_id_mobile'
            :value="primaryContact.id"
            :show-label="false"
            :mobile-row-layout="true"
            :options="[
              { label: `${primaryContact.mobile || 'Geen gsm-nummer'}`, value: primaryContact.id },
              { label: `${mergedContact.mobile || 'Geen gsm-nummer'}`, value: mergedContact.id }
            ]"
          />
          <CustomRadioGroup
            v-if="primaryContact.phone !== mergedContact.phone"
            v-model="values.contact_id_phone"
            name='contact_id_phone'
            :value="primaryContact.id"
            :show-label="false"
            :mobile-row-layout="true"
            :options="[
              { label: `${primaryContact.phone || 'Geen telefoonnummer'}`, value: primaryContact.id },
              { label: `${mergedContact.phone || 'Geen telefoonnnummer'}`, value: mergedContact.id }
            ]"
          />
          <CustomRadioGroup
            v-if="addressForPrimaryContact !== addressForMergedContact"
            v-model="values.contact_id_address"
            name='contact_id_address'
            :value="primaryContact.id"
            :show-label="false"
            :mobile-row-layout="true"
            :options="[
              { label: addressForPrimaryContact, value: primaryContact.id },
              { label: addressForMergedContact, value: mergedContact.id }
            ]"
          />
          <template v-if="primaryContact.type === 'B'">
            <CustomRadioGroup
              v-if="primaryContact.company_type !== mergedContact.company_type"
              v-model="values.contact_id_company_type"
              name='contact_id_company_type'
              :value="primaryContact.id"
              :show-label="false"
              :mobile-row-layout="true"
              :options="[
                { label: `${primaryContact.company_type_display}`, value: primaryContact.id },
                { label: `${mergedContact.company_type_display}`, value: mergedContact.id },
              ]"
            />
            <CustomRadioGroup
              v-if="primaryContact.vat !== mergedContact.vat"
              v-model="values.contact_id_vat"
              name='contact_id_vat'
              :value="primaryContact.id"
              :show-label="false"
              :mobile-row-layout="true"
              :options="[
                { label: `${primaryContact.vat || 'Geen ondernemingsnummer'}`, value: primaryContact.id },
                { label: `${mergedContact.vat || 'Geen ondernemingsnummer'}`, value: mergedContact.id }
              ]"
            />
            <CustomRadioGroup
              v-if="primaryContact.is_vat_liable !== mergedContact.is_vat_liable"
              v-model="values.contact_id_is_vat_liable"
              name='contact_id_is_vat_liable'
              :value="primaryContact.id"
              :show-label="false"
              :mobile-row-layout="true"
              :options="[
                { label: `${primaryContact.is_vat_liable ? 'Btw-plichtig: ja' : 'Btw-plichtig: nee'}`, value: primaryContact.id },
                { label: `${mergedContact.is_vat_liable ? 'Btw-plichtig: ja' : 'Btw-plichtig: nee'}`, value: mergedContact.id },
              ]"
            />
            <CustomRadioGroup
              v-if="primaryContact.type === 'B' && primaryContact.website !== mergedContact.website"
              v-model="values.contact_id_website"
              name='contact_id_website'
              :value="primaryContact.id"
              :show-label="false"
              :mobile-row-layout="true"
              :options="[
                { label: `${primaryContact.website ? primaryContact.website : 'Geen website'}`, value: primaryContact.id },
                { label: `${mergedContact.website ? mergedContact.website : 'Geen website'}`, value: mergedContact.id },
              ]"
            />
          </template>
          <template v-if="primaryContact.type === 'P'">
            <CustomRadioGroup
              v-if="primaryContact.birth_place !== mergedContact.birth_place"
              v-model="values.contact_id_birth_place"
              name='contact_id_birth_place'
              :value="primaryContact.id"
              :show-label="false"
              :mobile-row-layout="true"
              :options="[
                { label: `${primaryContact.birth_place ? `Geboorteplaats: ${primaryContact.birth_place.name}` : 'Geen geboorteplaats'}`, value: primaryContact.id },
                { label: `${mergedContact.birth_place ? `Geboorteplaats: ${mergedContact.birth_place.name}` : 'Geen geboorteplaats'}`, value: mergedContact.id },
              ]"
            />
            <CustomRadioGroup
              v-if="primaryContact.birth_date !== mergedContact.birth_date"
              v-model="values.contact_id_birth_date"
              name='contact_id_birth_date'
              :value="primaryContact.id"
              :show-label="false"
              :mobile-row-layout="true"
              :options="[
                { label: `${primaryContact.birth_date ? `${formatDateNlBe(primaryContact.birth_date, 'dd-MM-yyyy')}` : 'Geen geboortedatum'}`, value: primaryContact.id },
                { label: `${mergedContact.birth_date ? `${formatDateNlBe(mergedContact.birth_date, 'dd-MM-yyyy')}` : 'Geen geboortedatum'}`, value: mergedContact.id },
              ]"
            />
            <CustomRadioGroup
              v-if="primaryContact.marital_status !== mergedContact.marital_status"
              v-model="values.contact_id_marital_status"
              name='contact_id_marital_status'
              :value="primaryContact.id"
              :show-label="false"
              :mobile-row-layout="true"
              :options="[
                { label: `${primaryContact.marital_status ? primaryContact.marital_status_display : 'Burgerlijke staat niet gekend'}`, value: primaryContact.id },
                { label: `${mergedContact.marital_status ? primaryContact.marital_status_display : 'Burgerlijke staat niet gekend'}`, value: mergedContact.id },
              ]"
            />
          </template>
        </div>
        <div class="tw-mt-8 tw-flex tw-justify-end">
          <FormulateErrors />
          <FormulateInput
            type="submit"
            title="Volgende stap"
            :input-class="['tw-w-full md:tw-w-auto']"
            :disabled="isLoading"
            outer-class="tw-my-2 tw-w-full md:tw-w-auto"
          >
            <i
              :class="[
                'fas tw-mr-1',
                isLoading ? 'fa-spinner-third fa-spin' : 'fa-arrow-right'
              ]"
            />
            Volgende stap
          </FormulateInput>
        </div>
      </template>
      <div v-else-if="contactsAreOfDifferentTypes" class="tw-font-bold tw-text-red-500">
        Contacten hebben een verschillend type (persoon/bedrijf). Samenvoegen is niet mogelijk.
      </div>
      <div v-else-if="duplicateContactSelection" class="tw-font-bold tw-text-red-500">
        Hetzelfde contact is tweemaal geselecteerd. Samenvoegen is niet mogelijk.
      </div>
      <div v-else class="tw-h-48 tw-rounded tw-border tw-border-dashed tw-border-gray-200 tw-bg-gray-50" />
    </FormulateForm>
  </div>
</template>

<script>
import { formatDateNlBe } from '@/utils/helpers'
import { getProjectsForContact, getPropertiesForContact } from '@/services/contacts'
import ContactGDPR from '@/components/contacts/contact_render_components/ContactGDPR'
import EntityCarousel from '@/components/properties/EntityCarousel'
import CustomRadioGroup from '@/components/base/CustomRadioGroup'

export default {
  name: 'ContactMergeModalStep1',
  components: {
    ContactGDPR,
    CustomRadioGroup,
    EntityCarousel
  },
  props: {
    preselectedPrimaryContact: {
      type: Object,
      required: false
    },
    preselectedMergedContact: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      primaryContact: null,
      mergedContact: null,
      propertiesPrimaryContact: [],
      propertiesMergedContact: [],
      projectsPrimaryContact: [],
      projectsMergedContact: [],
      values: {}
    }
  },
  computed: {
    addressForPrimaryContact () {
      if (!this.primaryContact) return ''
      return this.formatContactAddress(this.primaryContact)
    },
    addressForMergedContact () {
      if (!this.mergedContact) return ''
      return this.formatContactAddress(this.mergedContact)
    },
    currentSelectedEmailOptionIsNoEmail () {
      const contact = this.primaryContact.id === this.values?.contact_id_email ? this.primaryContact : this.mergedContact
      return !contact.email
    },
    contactsAreOfDifferentTypes () {
      if (!(this.primaryContact && this.mergedContact)) return false
      const types = [this.primaryContact?.type, this.mergedContact?.type]
      const uniqueTypes = [...new Set(types)]
      if (uniqueTypes.length > 1) return true
      return false
    },
    duplicateContactSelection () {
      return this.primaryContact && this.mergedContact && this.primaryContact.id === this.mergedContact.id
    }
  },
  created () {
    this.init()
  },
  methods: {
    formatDateNlBe,
    formatContactAddress (contact) {
      const addressParts = [contact.street, contact.number, contact.box]
      const address = addressParts.filter(part => part).join(' ')
      const cityStr = contact.city ? `, ${contact.city.zip_code} ${contact.city.name_nl}` : ''
      return `${address}${cityStr}` || 'Geen adresgegevens'
    },
    setValues () {
      const mergeAttributes = [
        'contact_id_customer_portal',
        'contact_id_email',
        'contact_id_address',
        'contact_id_mobile',
        'contact_id_phone',
        'contact_id_company_type',
        'contact_id_vat',
        'contact_id_is_vat_liable',
        'contact_id_website',
        'contact_id_birth_place',
        'contact_id_birth_date',
        'contact_id_marital_status'
      ]
      for (const attribute of mergeAttributes) {
        this.$set(this.values, attribute, this.primaryContact.id)
      }
    },
    emailAddressChanged () {
      // If the email address changes from "email" to the "no email", then we should select the customer portal option
      if (!this.primaryContact.user_id && !this.mergedContact.user_id) return
      const contactWithCurrentEmailSelection = this.primaryContact.id === this.values?.contact_id_email ? this.primaryContact : this.mergedContact
      if (contactWithCurrentEmailSelection.email) return
      const contactWithoutCustomerPortal = this.primaryContact.user_id ? this.mergedContact : this.primaryContact
      this.$set(this.values, 'contact_id_customer_portal', contactWithoutCustomerPortal.id)
    },
    switchContacts () {
      const primaryContactCopy = { ...this.primaryContact }
      this.primaryContact = this.mergedContact
      this.mergedContact = primaryContactCopy
      const propertiesPrimaryContactCopy = [...this.propertiesPrimaryContact]
      this.propertiesPrimaryContact = this.propertiesMergedContact
      this.propertiesMergedContact = propertiesPrimaryContactCopy
      const projectsPrimaryContactCopy = [...this.projectsPrimaryContact]
      this.projectsPrimaryContact = this.projectsMergedContact
      this.projectsMergedContact = projectsPrimaryContactCopy
      this.setValues()
    },
    async init () {
      if (this.preselectedPrimaryContact) this.primaryContact = this.preselectedPrimaryContact
      if (this.preselectedMergedContact) this.mergedContact = this.preselectedMergedContact
      const response = await Promise.all(
        [
          this.loadPropertiesForContact('primary'),
          this.loadPropertiesForContact('merged'),
          this.loadProjectsForContact('primary'),
          this.loadProjectsForContact('merged')
        ]
      )
      if (this.primaryContact && this.mergedContact) this.setValues()
      return response
    },
    async processContactSelection (contactType) {
      await this.loadPropertiesForContact(contactType)
      if (this.primaryContact && this.mergedContact) this.setValues()
    },
    async loadPropertiesForContact (contactType) {
      const contact = contactType === 'primary' ? this.primaryContact : this.mergedContact
      const contactId = contact?.id
      if (!contactId) return false
      const response = await getPropertiesForContact(contactId, { statuses: 'all', page_size: 1000 })
      contactType === 'primary'
        ? this.propertiesPrimaryContact = response?.data?.results
        : this.propertiesMergedContact = response?.data?.results
      return response
    },
    async loadProjectsForContact (contactType) {
      const contact = contactType === 'primary' ? this.primaryContact : this.mergedContact
      const contactId = contact?.id
      if (!contactId) return false
      const response = await getProjectsForContact(contactId, { statuses: 'all', page_size: 1000 })
      contactType === 'primary'
        ? this.projectsPrimaryContact = response?.data?.results
        : this.projectsMergedContact = response?.data?.results
      return response
    },
    submit (data) {
      const payload = { ...data }
      payload.primary_contact = this.primaryContact.id
      payload.merged_contact = this.mergedContact.id
      const emitPayload = {
        mergePayload: payload,
        primaryContact: this.primaryContact,
        mergedContact: this.mergedContact
      }
      this.$emit('finished', emitPayload)
    }
  }
}
</script>
