<template>
  <div :class="['tw-gap-x-2 md:tw-grid', showLabel ? '!tw-my-2 tw-grid-cols-3' : '!tw-my-4 tw-grid-cols-2']">
    <label
      :class="['formulate-label tw-flex tw-items-center', indentLabel ? 'md:tw-pl-8' : '']"
    >
      {{ label }}
    </label>
    <div
      :class="['tw-flex tw-w-full tw-col-span-2', mobileRowLayout ? 'tw-flex-col sm:tw-flex-row' : '', showLabel ? 'tw-gap-2' : 'tw-gap-x-16 tw-gap-y-2']"
    >
      <div
        v-show="!option.hidden"
        v-for="option in options"
        :key="option.value"
        class="tw-flex-1"
      >
        <input
          v-model="selectedValue"
          :id="`${name}-${option.value}`"
          :value="option.value"
          type="radio"
          :name="name"
          class="tw-peer tw-sr-only"
          :disabled="disabled"
          @click="unset(option.value)"
        />
        <label
          class="
            tw-flex tw-justify-center tw-flex-wrap tw-bg-white tw-border tw-pl-4 tw-pr-2
            tw-border-gray-300 tw-rounded tw-text-grey-2 tw-text-sm tw-cursor-pointer
            focus:tw-outline-none hover:tw-bg-gray-50 peer-checked:tw-text-gray-700
            peer-checked:tw-border-gray-700 peer-checked:tw-font-bold tw-w-full tw-py-2 !tw-mb-0
          "
          :for="`${name}-${option.value}`"
        >
           <span v-if="option.value === selectedValue" class="tw-mr-1">
              <i class="fa fa-check" />
            </span>
            <span>{{ option.label }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    value: {
      type: [Number, String],
      default: ''
    },
    options: {
      type: Array,
      required: true
    },
    name: { // Unique group name to prevent conflicts
      type: String,
      required: true
    },
    indentLabel: {
      type: Boolean,
      default: false
    },
    mobileRowLayout: { // if 'true' the options are shown underneath eachother on mobile
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    unset (value) {
      if (value !== this.selectedValue) return
      const defaultOption = this.options.find(option => option.default)
      if (defaultOption) {
        this.$emit('input', defaultOption.value)
      }
    }
  },
  computed: {
    selectedValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
